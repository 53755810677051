import type { Route } from 'next'
import Link from 'next/link'
import styles from './CommonFooter.module.scss'
import bannerAppStore from '@/assets/images/banner/banner-app-store.png'
import bannerGooglePlay from '@/assets/images/banner/banner-google-play.png'
import utilsStyles from '@/styles/modules/utils.module.scss'

function CommonFooter() {
  const menuList = [
    { to: '/faq', text: 'よくある質問' },
    { to: '/contact', text: 'お問い合わせ' },
    { to: '/terms/term', text: '利用規約' },
    {
      to: '/terms/policy',
      text: 'プライバシーポリシー',
    },
    { to: '/terms/tradelaw', text: '特定商取引法に基づく表記' },
    { to: '/terms/paymentlaw', text: '資金決済法に基づく表記' },
  ]
  return (
    <footer className={styles.componentStyle}>
      <div className={styles.container}>
        <div className={styles.bannerContainer}>
          <a href={process.env.NEXT_PUBLIC_APP_STORE_URL}>
            <img
              src={bannerAppStore.src}
              alt='appStore'
              className={styles.appBanner}
            />
          </a>
          <a href={process.env.NEXT_PUBLIC_GOOGLE_PLAY_STORE_URL}>
            <img
              src={bannerGooglePlay.src}
              alt='googlePlay'
              className={styles.appBanner}
            />
          </a>
        </div>
        <ul className={styles.menuList}>
          {menuList.map((link, index) => (
            <li
              key={`inner_${
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                index
              }`}
            >
              <Link
                href={link.to as Route}
                className={utilsStyles.textLink}
                prefetch={false}
              >
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
        <p className={styles.copyright}>&copy;&nbsp;CHEERZ</p>
      </div>
    </footer>
  )
}

export default CommonFooter
