'use client' // Error components must be Client components

import '@/styles/reset.scss'
import '@/styles/global.scss'
import * as Sentry from '@sentry/nextjs'
import ErrorPage from '@/components/errors/ErrorPage/ErrorPage'
import LinkButton from '@/components/form/LinkButton/LinkButton'
import CommonFooter from '@/components/layouts/CommonFooter/CommonFooter'
import CommonHeader from '@/components/layouts/CommonHeader/CommonHeader'
import CommonMenu from '@/components/layouts/CommonMenu/CommonMenu'
import layoutStyles from '@/styles/modules/layout.module.scss'
import utilsStyles from '@/styles/modules/utils.module.scss'

// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
export default function Error({ error }: { error: Error }) {
  if (error) {
    Sentry.captureException(error)
    Sentry.flush(2000)
  }
  return (
    <>
      <CommonHeader />
      <CommonMenu />
      <div className={layoutStyles.pageWrapper}>
        <div className={layoutStyles.page}>
          <main>
            <div className={layoutStyles.pageContainer}>
              <div className={utilsStyles.mtSmall}>
                <ErrorPage
                  statusCode={500}
                  name={'SERVER ERROR'}
                  description={'エラーが発生しました。'}
                />
              </div>
              <div
                className={`${layoutStyles.centeredBtnWrapper} ${utilsStyles.mtMedium}`}
              >
                <LinkButton href={'/'}>トップページへ</LinkButton>
              </div>
            </div>
          </main>
        </div>
      </div>
      <CommonFooter />
    </>
  )
}
