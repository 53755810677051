import type { UrlObject } from 'node:url'
import type { Route } from 'next'
import Link from 'next/link'
import styles from './LinkButton.module.scss'

function LinkButton<T extends string>({
  children,
  href,
  size = 'default',
  color = 'white',
  bgColor = 'primary',
  outlined = false,
}: {
  children: React.ReactNode
  href: Route<T> | UrlObject
  size?: 'default' | 'small' | 'wide' | 'fullWidth' | 'autoWidth'
  /** 参照: src/styles/partials/_variables.scss */
  color?: string
  bgColor?: string
  outlined?: boolean
}) {
  return (
    <div
      className={`${styles.linkButton} ${styles[size]}`}
      style={generateStyle({ color, bgColor, outlined })}
    >
      <Link href={href} prefetch={false} className={styles.link}>
        {children}
      </Link>
    </div>
  )
}

const generateStyle = ({
  color,
  bgColor,
  outlined,
}: {
  color?: string
  bgColor?: string
  outlined?: boolean
}): React.CSSProperties => {
  if (outlined) {
    return {
      color: `var(--color-${color})`,
      border: `2px solid var(--color-${color})`,
      background: `var(--color-${bgColor})`,
    }
  }

  return {
    border: `2px solid var(--color-${bgColor})`,
    color: `var(--color-${color})`,
    background: `var(--color-${bgColor})`,
  }
}

export default LinkButton
